import BlockContent from '@sanity/block-content-to-react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import React, { useContext, useState } from 'react'
import { serializers } from '../common'
import { Context, DATASET, PROJECT_ID } from '../layouts'

// local components

const ConditionalLink = ({ currentLink, link, post, setCurrentLink }) => {
  const classes = () => classNames('post__link', { 'post__link--current': post.slug.current === currentLink })

  const handleClick = ({ currentTarget }) => {
    if (currentTarget.parentNode !== document.querySelector('.post:first-child')) {
      const position = currentTarget.getBoundingClientRect()

      setPostPosition({ left: position.left - 3, top: position.top })
    }
  }

  const handleMouseOver = ({ currentTarget }) => setCurrentLink(currentTarget.href.match(/^https?:\/\/[^/]+(.*)/)[1])

  const { setPostPosition } = useContext(Context)

  if (!link) return null

  return <Link className={classes()} onClick={handleClick} onMouseOver={handleMouseOver} to={post.slug.current} />
}

const Summary = ({ summary }) =>
  summary ? (
    <div className="post__summary">
      <BlockContent blocks={summary} serializers={serializers} />
    </div>
  ) : null

const Title = ({ title, titleInBody }) => (titleInBody ? null : <h2 className="post__heading">{title}</h2>)

// exported components

export const Post = ({ classes, link, post, styles }) => {
  const handleMouseLeave = () => setCurrentLink(null)

  const [currentLink, setCurrentLink] = useState(null)

  return (
    <div className={classes} onMouseLeave={handleMouseLeave} style={styles}>
      <ConditionalLink currentLink={currentLink} link={link} post={post} setCurrentLink={setCurrentLink} />

      <div className="post__inner">
        <BlockContent blocks={post._rawThumbnailImage} dataset={DATASET} imageOptions={{ w: 290 }} projectId={PROJECT_ID} serializers={serializers} />

        <Title title={post.title} titleInBody={post.titleInBody} />

        <Summary summary={post._rawSummary} />
      </div>
    </div>
  )
}

export default Post
