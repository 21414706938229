module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.12.0__encoding@0.1.13__lcluybhu4gpn4el264i5h26vaa/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.12.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._qigci4motqmxuluu25hxzdxxpe/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
